/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ChangePassword from '../../pages/ChangePassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      {/* begin::Content */}
      <div className='d-flex justify-content-between align-items-center overflow-hidden h-100'>
        {/* begin::Wrapper */}
        <div className='bg-success h-100 w-25 d-none d-lg-flex flex-column justify-content-between'>
          <div className='h-50 d-flex flex-col justify-content-center align-items-center'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo_new.png')} className='h-150px ' />
            {/* <h2 className='text-white mx-auto'>PG planner admin panel</h2> */}
          </div>
          <div
            className='h-50 bgi-position-y-bottom bgi-position-x-center bgi-no-repeat bgi-size-contain'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/custom/login-page-art.png')})`,
            }}
          ></div>
        </div>
        <div className='bg-body w-100 w-lg-75'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='change-password' element={<ChangePassword />} /> */}
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
