import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://production.pgplanner.in',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

export default axiosInstance
