import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field, Form, Formik, useFormik} from 'formik'
import {changePasswordWithOtp, getPasswordResetOtp, requestPassword} from '../core/_requests'
import ModalWrapper from '../../../pages/sharedComponents/ModalWrapper'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const res = await getPasswordResetOtp(values.email)
      if (res?.data) {
        // data-bs-toggle="modal"
        // data-bs-target="#kt_modal_1"
        const toggleModalButton = document.createElement('button')
        toggleModalButton.setAttribute('type', 'button')
        toggleModalButton.setAttribute('data-bs-toggle', 'modal')
        toggleModalButton.setAttribute('data-bs-target', '#otp-modal')
        document.body.appendChild(toggleModalButton)
        console.log(toggleModalButton)
        toggleModalButton.click()
      }
    },
  })

  return (
    <>
      <form
        className='form w-50 mx-auto fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      <ModalWrapper id='otp-modal'>
        <div className='modal-header'>
          <h5 className='modal-title'>Enter New Password</h5>
        </div>
        <Formik
          initialValues={{email: '', password: '', otp: ''}}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email is required.'),
            password: Yup.string().required('Password is required'),
            otp: Yup.string().required('OTP is required'),
          })}
          onSubmit={async (values) => {
            const res = await changePasswordWithOtp(values)
            if (res?.data) {
              document.getElementById('dismiss-password-modal')?.click()
            }
          }}
        >
          <Form>
            <div className='modal-body'>
              <label className='form-label fw-bolder text-gray-900 fs-6 w-100'>
                Email
                <Field type='text' name='email' className='form-control' />
                <ErrorMessage name='email' component={'p'} className='fw-bolder text-danger fs-6' />
              </label>
              <label className='form-label fw-bolder text-gray-900 fs-6 w-100'>
                Password
                <Field type='password' name='password' className='form-control' />
                <ErrorMessage
                  name='password'
                  component={'p'}
                  className='fw-bolder text-danger fs-6'
                />
              </label>
              <label className='form-label fw-bolder text-gray-900 fs-6 w-100'>
                OTP
                <Field type='text' name='otp' className='form-control' />
                <ErrorMessage name='otp' component={'p'} className='fw-bolder text-danger fs-6' />
              </label>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='dismiss-password-modal'
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary'>
                Reset Password
              </button>
            </div>
          </Form>
        </Formik>
      </ModalWrapper>
    </>
  )
}
