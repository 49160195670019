import {useContext, createContext, FC, ReactNode, useState, useEffect} from 'react'
import catchAsync from '../../helpers/catchAsync'
import axiosInstance from '../../helpers/axiosInstance'
import {getAttributeValueByBreakpoint} from '../../../_metronic/assets/ts/_utils'
import {getAuth, useAuth} from '../auth'

interface StatesAndCities {
  statesandcities: {
    [stateName: string]: {
      city: string
    }
  }[]
}

const initialState: StatesAndCities = {
  statesandcities: [],
}

const StatesAndCitiesContext = createContext<StatesAndCities>(initialState)

const useStatesAndCities = () => {
  return useContext(StatesAndCitiesContext)
}

const getStates = (availableStatesAndCities: StatesAndCities): string[] => {
  const states = availableStatesAndCities.statesandcities.map(
    (stateObj) => Object.keys(stateObj)[0]
  )

  return states
}

const getCitiesByState = (
  availableStatesAndCities: StatesAndCities,
  stateName: string
): string[] => {
  const state = availableStatesAndCities.statesandcities.find((obj) => stateName in obj)
  if (state) {
    // @ts-ignore
    const cities = state[stateName].map((cityObj) => cityObj.city)
    return cities
  }
  return []
}

const StatesAndCitiesProvider: FC<{children: ReactNode}> = ({children}) => {
  const [statesAndCities, setStatesAndCities] = useState<StatesAndCities>(initialState)
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) {
      catchAsync(async () => {
        const res = await axiosInstance.get('/api/pgowner/getlistofstateandcitites')
        setStatesAndCities(res.data)
      })()
    }
  }, [])

  return (
    <StatesAndCitiesContext.Provider value={statesAndCities}>
      {children}
    </StatesAndCitiesContext.Provider>
  )
}

export {
  StatesAndCitiesProvider,
  useStatesAndCities,
  StatesAndCitiesContext,
  getStates,
  getCitiesByState,
}
