/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget3} from '../../../_metronic/partials/widgets'
import IsolatedSection from '../sharedComponents/IsolatedSection'
import SelectFieldWithInnerLabel from '../sharedComponents/SelectFieldWithInnerLabel'
import catchAsync from '../../helpers/catchAsync'
import axiosInstance from '../../helpers/axiosInstance'

interface Stats {
  users: number
  newuserstoday: number
  userswithoutpg: number
  pgs: number
  totalbeds: number
  vacantbeds: number
  appointmentcovered: number
  dndbeds: number
  noticeperiodbeds: number
  monthlybookings: number
  dailybookings: number
  hourlybookings: number
  totalwalletbalance: number
  usersbymonth: UserByMonth[]
  bookingsbymonth: BookingByMonth[]
  amountpaidtowallet: number
}

interface UserByMonth {
  count: number
  month: number
}

interface BookingByMonth {
  count: number
  month: number
}

const DashBoardCard: FC<{iconPath: string; title: string; body: string}> = ({
  iconPath,
  title,
  body,
}) => {
  return (
    <article className='w-full px-10 py-20 rounded bg-white shadow-sm flex gap-5 items-center'>
      <KTSVG path={iconPath} className='svg-icon-4x bg-blue-100 rounded-full p-10' />
      <div className='w-full text-center text-capitalize'>
        <span className='fs-3 fw-bold'>{title}</span>
        <br />
        <span className='fs-1 fw-bold'>{body}</span>
      </div>
    </article>
  )
}

const DashboardPage: FC = () => {
  const [stats, setStats] = useState<Stats>()
  const [monthFilter, setMonthFilter] = useState('')

  const cards = [
    {
      iconPath: '/media/icons/custom/user.svg',
      title: 'All Users',
      body: stats?.users as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/user.svg',
      title: 'New Users',
      body: stats?.newuserstoday as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/user.svg',
      title: 'Users Without PG',
      body: stats?.userswithoutpg as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/building.svg',
      title: 'Total PGs',
      body: stats?.pgs as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/bed.svg',
      title: 'Total Beds',
      body: stats?.totalbeds as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/bed.svg',
      title: 'Vacant Beds',
      body: stats?.vacantbeds as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/user.svg',
      title: 'Appointment Covered',
      body: stats?.appointmentcovered as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/bed.svg',
      title: 'DND Beds',
      body: stats?.dndbeds as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/bed.svg',
      title: 'Notice Period Beds',
      body: stats?.noticeperiodbeds as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/folder.svg',
      title: 'Monthly Bookings',
      body: stats?.monthlybookings as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/folder.svg',
      title: 'Daily Bookings',
      body: stats?.dailybookings as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/folder.svg',
      title: 'Hourly Bookings',
      body: stats?.hourlybookings as unknown as string,
    },
    {
      iconPath: '/media/icons/custom/wallet.svg',
      title: 'Wallet Amount',
      body: `₹${stats?.totalwalletbalance as unknown as string}`,
    },
    {
      iconPath: '/media/icons/custom/wallet.svg',
      title: 'Amount Paid In Wallet',
      body: `₹${stats?.amountpaidtowallet as unknown as string}`,
    },
  ]

  useEffect(() => {
    catchAsync(async () => {
      let url = '/api/superadmin/getdashboardstats'
      if (monthFilter.length) {
        url += `?month=${monthFilter}`
      }
      const res = await axiosInstance(url)
      setStats(res.data)
    })()
  }, [monthFilter])
  const monthhash: {
    [key: number]: string
  } = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May ',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'Octomber',
    11: 'November',
    12: 'December',
  }
  return (
    <section className=''>
      <div className='mb-7 flex justify-between'>
        <div>
          <h1>Dashboard</h1>
          <span className='text-primary'>Dashboard</span>
        </div>
        <div>
          <SelectFieldWithInnerLabel
            label={<KTIcon iconName='filter' className='fs-6 text-primary ms-10' />}
            labelPosition='start'
            defaultValue={''}
            onChange={(e) => setMonthFilter(e.target.value)}
          >
            <option value='' disabled>
              Select month
            </option>
            {[
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ].map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </SelectFieldWithInnerLabel>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-y-10'>
        {cards.map((card) => (
          <DashBoardCard key={card.title} {...card} />
        ))}
      </div>
      {stats && (
        <>
          <ChartsWidget3
            title='Users By Month'
            className='bg-white rounded shadow-sm mt-4 mb-4'
            categories={stats?.usersbymonth.map((x) => monthhash[x.month])}
            data={stats?.usersbymonth.map((x) => x.count)}
          />
          <ChartsWidget3
            title='Bookings By Month'
            className='bg-white rounded shadow-sm mt-4 mb-4'
            categories={stats?.bookingsbymonth.map((x) => monthhash[x.month])}
            data={stats?.bookingsbymonth.map((x) => x.count)}
          />
        </>
      )}
    </section>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
