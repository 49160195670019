import axios, {AxiosError} from 'axios'
import {AuthModel, UserModel} from './_models'
import {toast} from 'react-toastify'
import {setAuth} from './AuthHelpers'
const API_URL = 'https://production.pgplanner.in/api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const ADMIN_LOGIN_URL = `${API_URL}/login/admin`
export const REQUEST_OTP_FOR_PASSWORD_CHANGE_URL = `${API_URL}/pgowner/requestotp`
export const CHANGE_PASSWORD_WITH_OTP_URL = `${API_URL}/pgowner/changepasswordwithotp`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export async function adminLogin(email: string, password: string) {
  const data = new URLSearchParams()
  data.append('email', email)
  data.append('password', password)

  try {
    const res = await axios.post<User>(ADMIN_LOGIN_URL, data)
    localStorage.setItem('token', res.data.user.token)
    setAuth(res.data)
    window.location.reload()
    return res
  } catch (error) {
    const axiosError = error as AxiosError
    toast.error(axiosError.response?.data.error)
  }
}

export async function getPasswordResetOtp(email: string) {
  const data = new URLSearchParams()
  data.append('email', email)

  try {
    const res = await axios.post(REQUEST_OTP_FOR_PASSWORD_CHANGE_URL, data)
    toast.success(res.data.message)
    return res
  } catch (error) {
    const axiosError = error as AxiosError
    toast.error(axiosError.response?.data.error)
  }
}

interface ChangePasswordWithOtpData {
  email: string
  password: string
  otp: string
}
export async function changePasswordWithOtp(info: ChangePasswordWithOtpData) {
  const data = new URLSearchParams()
  data.append('email', info.email)
  data.append('password', info.password)
  data.append('otp', info.otp)

  try {
    const res = await axios.put(CHANGE_PASSWORD_WITH_OTP_URL, data)
    toast.success(res.data.message)
    return res
  } catch (error) {
    const axiosError = error as AxiosError
    toast.error(axiosError.response?.data.error)
  }
}
