/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {getAuth} from '../../../../../app/modules/auth'
import catchAsync from '../../../../../app/helpers/catchAsync'
import axiosInstance from '../../../../../app/helpers/axiosInstance'

interface UserAccess {
  bookings: boolean
  jobs: boolean
  market: boolean
  news: boolean
  subscriptions: boolean
  servicerequests: boolean
}

const SidebarMenuMain = () => {
  const intl = useIntl()
  const auth = getAuth()

  const [userAccess, setUserAccess] = useState<UserAccess>()
  useEffect(() => {
    catchAsync(async () => {
      const res = await axiosInstance.get('/api/superadmin/getuseraccess')
      console.log(res.data)
      setUserAccess(res.data.useraccess)
    })()
  }, [])

  return (
    <>
      {['superadmin', 'regionaladmin', 'salesexecutive'].includes(
        auth?.user.usertype as string
      ) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/users'
            icon='element-11'
            title='Users'
            fontIcon='bi-app-indicator'
          >
            {['superadmin', 'regionaladmin', 'salesexecutive'].includes(
              auth?.user.usertype as string
            ) && (
              <>
                <SidebarMenuItem title='PG List' to='/users/pg-list/pgs' hasBullet />
                <SidebarMenuItem
                  title='Hostel Owners'
                  to='/users/owner-list/hostel-owners'
                  hasBullet
                />
              </>
            )}
            {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) && (
              <SidebarMenuItem title='Users' to='/users/user-list/users' hasBullet />
            )}
          </SidebarMenuItemWithSub>
        </>
      )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Finance</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/finance'
            iconPath='/media/icons/duotune/finance/fin006.svg'
            title='Subscriptions'
            fontIcon='bi-app-indicator'
          >
            {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) &&
              userAccess?.subscriptions && (
                <>
                  <SidebarMenuItem
                    title='Subscriptions'
                    to='/subscriptions/subscriptions'
                    hasBullet
                  />
                  <SidebarMenuItem title="Applied PG's" to='/subscriptions/applied-pgs' hasBullet />
                </>
              )}
          </SidebarMenuItemWithSub>
        </>
      )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) && (
        <>
          {' '}
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PG Team</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/company-team'
            icon='switch'
            title='Company Team'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem title='Regional Admins' to='/company-team/regional-admins' />
            <SidebarMenuItem title='Employees' to='/company-team/employees' />
            <SidebarMenuItem title='Sales Executives' to='/company-team/sales-executives' />
          </SidebarMenuItemWithSub>
        </>
      )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Store Management
              </span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/store-management'
            iconPath='/media/icons/duotune/ecommerce/ecm004.svg'
            title='Store Management'
          >
            {userAccess?.news && (
              <SidebarMenuItem to='/store-management/news' title='News Update' hasBullet />
            )}
          </SidebarMenuItemWithSub>
        </>
      )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) &&
        userAccess?.bookings && (
          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Bookings</span>
              </div>
            </div>
            <SidebarMenuItemWithSub
              to='/bookings'
              iconPath='/media/icons/duotune/ecommerce/ecm001.svg'
              title='Bookings'
            >
              <SidebarMenuItem to='/bookings/bookings' title='Bookings' hasBullet />
            </SidebarMenuItemWithSub>
          </>
        )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Reports</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/forum'
            icon='element-1'
            title='PG Forum'
            fontIcon='bi-app-indicator'
          >
            {userAccess?.jobs && <SidebarMenuItem title='PG Jobs' to='/forum/pg-jobs' hasBullet />}
            {userAccess?.market && (
              <SidebarMenuItem title='PG Market' to='/forum/pg-market' hasBullet />
            )}
          </SidebarMenuItemWithSub>
        </>
      )}
      {['superadmin', 'regionaladmin'].includes(auth?.user.usertype as string) &&
        userAccess?.servicerequests && (
          <>
            <SidebarMenuItem to='/service/service-request' title='Service Requests' hasBullet />
          </>
        )}
    </>
  )
}

export {SidebarMenuMain}
