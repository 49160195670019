import React, {FC, ReactNode, SelectHTMLAttributes, useRef} from 'react'

interface IInputFieldWithCopyButtonProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: ReactNode
  labelPosition?: 'start' | 'end'
  labelClassName?: string
  children: ReactNode
}

const SelectFieldWithInnerLabel: FC<IInputFieldWithCopyButtonProps> = ({
  label,
  labelPosition = 'end',
  className,
  labelClassName,
  children,
  ...restProps
}) => {
  const inputRef = useRef<HTMLSelectElement>(null)
  return (
    <div className='position-relative w-100 max-w-fit'>
      <select
        className={'form-select form-select-solid px-10 ' + className}
        ref={inputRef}
        {...restProps}
      >
        {children}
      </select>
      <div
        className={
          `position-absolute ${labelPosition}-0 top-50 translate-middle cursor-pointer py-3 ` +
          labelClassName
        }
      >
        {label}
      </div>
    </div>
  )
}

export default SelectFieldWithInnerLabel
