import React, {FC, ReactNode} from 'react'

const ModalWrapper: FC<{id: string; modalSize?: 'sm' | 'lg' | 'xl'; children: ReactNode}> = ({
  id,
  modalSize,
  children,
}) => {
  return (
    <div className='modal fade' tabIndex={-1} id={id}>
      <div className={`modal-dialog modal-${modalSize}`}>
        <div className='modal-content'>{children}</div>
      </div>
    </div>
  )
}

export default ModalWrapper
