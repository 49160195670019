import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { AuthPage, useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  // pg admin pages
  // users
  // owner list
  const HostelOwnersPage = lazy(() => import('../pages/pgOwnerPages/hostelOwners/HostelOwners'))
  const OwnerDetailsPage = lazy(() => import('../pages/pgOwnerPages/hostelOwners/OwnerDetails'))
  // pg list
  const AppliedPGsPage = lazy(() => import('../pages/AppliedPGs'))
  const PGsListPage = lazy(() => import('../pages/PGsList'))
  const PGDetailsPage = lazy(() => import('../pages/pgOwnerPages/pgDetails/PGDetails'))
  // user list
  const UsersPage = lazy(() => import('../pages/userPages/users/Users'))
  const UserProfilePage = lazy(() => import('../pages/userPages/users/UserProfile'))
  // employees
  const SalesExecutivesPage = lazy(
    () => import('../pages/employeesPages/salesExecutives/SalesExecutives')
  )
  const SalesExecutiveDetailsPage = lazy(
    () => import('../pages/employeesPages/salesExecutives/SalesExecutiveDetails')
  )
  const EmployeesPage = lazy(() => import('../pages/employeesPages/employees/Employees'))
  const EmployeeDetailsPage = lazy(
    () => import('../pages/employeesPages/employees/EmployeeDetails')
  )
  const RegionalAdminsPage = lazy(
    () => import('../pages/employeesPages/regionalAdmins/RegionalAdmins')
  )
  const RegionalAdminDetailsPage = lazy(
    () => import('../pages/employeesPages/regionalAdmins/RegionalAdminDetails')
  )
  // bookings
  const BookingsPage = lazy(() => import('../pages/bookingsPages/bookings/Bookings'))
  // finance
  const SubscriptionsPage = lazy(
    () => import('../pages/subscriptionPages/subscriptions/Subscriptions')
  )
  // forum
  const PgJobsPage = lazy(() => import('../pages/forumPages/pgJobs/PgJobs'))
  const PgMarketPage = lazy(() => import('../pages/forumPages/pgMarket/PgMarket'))
  // store management pages
  // news
  const NewsPage = lazy(() => import('../pages/storeManagementPages/news/News'))
  // service pages
  // service requests
  const ServiceRequestPage = lazy(
    () => import('../pages/servicePages/serviceRequests/ServiceRequests')
  )
  // change password page

  const ChangePasswordPage = lazy(() => import('../pages/ChangePassword'))

  return (
    <Routes>
      <Route
        path='/change-password'
        element={
          <SuspensedView>
            <ChangePasswordPage />
          </SuspensedView>
        }
      />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            ['salesexecutive'].includes(currentUser?.usertype as string) ? (
              <Navigate to='/users/pg-list/pgs' />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* pg admin routes */}
        <Route path='users'>
          <Route path='owner-list'>
            <Route
              path='hostel-owners'
              element={
                <SuspensedView>
                  <HostelOwnersPage />
                </SuspensedView>
              }
            />
            <Route
              path='hostel-owners/:id'
              element={
                <SuspensedView>
                  <OwnerDetailsPage />
                </SuspensedView>
              }
            />
          </Route>
          <Route path='pg-list'>
            <Route
              path='pgs'
              element={
                <SuspensedView>
                  <PGsListPage />
                </SuspensedView>
              }
            />
          </Route>
          <Route path='user-list'>
            <Route
              path='users'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
            <Route
              path='users/:id'
              element={
                <SuspensedView>
                  <UserProfilePage />
                </SuspensedView>
              }
            />
          </Route>
        </Route>
        <Route path='company-team'>
          <Route
            path='sales-executives'
            element={
              <SuspensedView>
                <SalesExecutivesPage />
              </SuspensedView>
            }
          />
          <Route
            path='sales-executives/:id'
            element={
              <SuspensedView>
                <SalesExecutiveDetailsPage />
              </SuspensedView>
            }
          />
          <Route
            path='employees'
            element={
              <SuspensedView>
                <EmployeesPage />
              </SuspensedView>
            }
          />
          <Route
            path='employees/:id'
            element={
              <SuspensedView>
                <EmployeeDetailsPage />
              </SuspensedView>
            }
          />
          <Route
            path='regional-admins'
            element={
              <SuspensedView>
                <RegionalAdminsPage />
              </SuspensedView>
            }
          />
          <Route
            path='regional-admins/:id'
            element={
              <SuspensedView>
                <RegionalAdminDetailsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='bookings'>
          <Route
            path='bookings'
            element={
              <SuspensedView>
                <BookingsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='subscriptions'>
          <Route
            path='subscriptions'
            element={
              <SuspensedView>
                <SubscriptionsPage />
              </SuspensedView>
            }
          />
          <Route
            path='applied-pgs'
            element={
              <SuspensedView>
                <AppliedPGsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='forum'>
          <Route
            path='pg-jobs'
            element={
              <SuspensedView>
                <PgJobsPage />
              </SuspensedView>
            }
          />
          <Route
            path='pg-market'
            element={
              <SuspensedView>
                <PgMarketPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='store-management'>
          <Route
            path='news'
            element={
              <SuspensedView>
                <NewsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='service'>
          <Route
            path='service-request'
            element={
              <SuspensedView>
                <ServiceRequestPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='pg-details/:id'
          element={
            <SuspensedView>
              <PGDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/user-management/users'
          element={
            <SuspensedView>
              <UsersTemplatePage />
            </SuspensedView>
          } 
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
